import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const styles = {
  card: {
    maxWidth: 345
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  }
};

function MediaCard(props) {
  const {
    classes,
    title,
    result,
    materialPerQm,
    totalKiloPer15Kilo,
    image,
    shopLink,
    productLink
  } = props;
  return (
    <div>
      <Card className={classes.card}>
        <CardMedia
          className={classes.media}
          image={image ? image : "/images/terraflex-4.jpg"}
          title="product-image"
        />
        <CardContent>
          <Typography
              gutterBottom
              variant="headline"
              component="h2"
              style={{ fontSize: 20, fontWeight: 500 }}
          >
            {title}
          </Typography>
          <Typography component="p">
            Gesamtbedarf: {result} kg <br />
            Verbrauch: {materialPerQm} kg/m&sup2; <br />
            <br />
            Anzahl in 15 kg Gebinden: {totalKiloPer15Kilo} Eimer <br />
            <br />
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" href={productLink ? productLink : "#"}>
            zum Produkt
          </Button>
          <Button size="small" color="primary" href={shopLink ? shopLink : "#"}>
            zum Shop
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}

MediaCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MediaCard);
