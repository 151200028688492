import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const styles = {
  card: {
    maxWidth: 345,
    minWidth: 280
  },
  media: {
    height: 0,
    paddingTop: "56.25%" // 16:9
  }
};

function ProductCard(props) {
  const { classes, product, apiUrl } = props;
  return (
    <div>
      <Card className={classes.card}>
        <CardMedia
          className={classes.media}
          image={apiUrl + 'images/' + product.imageUrl}
          title={product.title}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant="headline"
            component="h2"
            style={{ fontSize: 20, fontWeight: 500 }}
          >
            {product.title}
          </Typography>
          <Typography component="p" style={{ fontSize: 16 }}>
            > {product.descriptionOne}
          </Typography>
          <Typography component="p" style={{ fontSize: 16 }}>
            > {product.descriptionTwo}
          </Typography>
          <Typography component="p" style={{ fontSize: 16 }}>
            > {product.descriptionThree}
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" href={product.webLink}>
            Zum Artikel
          </Button>
        </CardActions>
      </Card>
    </div>
  );
}

ProductCard.propTypes = {
  classes: PropTypes.object.isRequired,
  product: PropTypes.object
};

export default withStyles(styles)(ProductCard);
