import React from "react";
import Paper from "@material-ui/core/Paper";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import InfoIcon from "@material-ui/icons/Info.js";
import EmailIcon from '@material-ui/icons/Email';
import ShoppingCart from "@material-ui/icons/ShoppingCart";

import "./style.css";

class Footer extends React.Component {
  state = {
    value: "rechner"
  };

  handleChange = (event, value) => {
    this.setState({ value });
    switch (value) {
      case "produkte":
        return window.location.href = this.props.siteUrl ? this.props.siteUrl : '#';
      case "impressum":
        return window.location.href = 'https://galapower.de/impressum/';
      case "contact": {
        return this.props.contactMail ? window.location.href = "mailto:" + this.props.contactMail : null;
      }
      case "rechner":
      default:
        return this.props.history.push("/rechner");
    }
  };

  render() {
    const { value } = this.state;

    return (
      <div className={"footer"}>
        <Paper>
          <BottomNavigation
            value={value}
            onChange={this.handleChange}
            showLabels
          >
            <BottomNavigationAction
              label="Webshop"
              value={"produkte"}
              icon={<ShoppingCart />}
            />
            <BottomNavigationAction
                label="Kontakt"
                value={"contact"}
                icon={<EmailIcon />}
            />
            <BottomNavigationAction
              label="Impressum"
              value={"impressum"}
              icon={<InfoIcon />}
            />
          </BottomNavigation>
        </Paper>
      </div>
    );
  }
}

export default Footer;
