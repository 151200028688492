/* eslint-disable react/no-multi-comp */

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import blue from "@material-ui/core/colors/blue";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import ProductCard from "../ProductCard";
import Slide from "@material-ui/core/Slide";
import MaterialButton from "../../atoms/MaterialButton";

/*const products = [
  {
    title: "TerraFLEX 1k Easy",
    image: "/images/Terraflex-easy-VR.jpg",
    description1: "> Fugen ab 4 mm",
    description2: "> Personenbelastung",
    description3: "> einschlämmbar",
    link: "https://multiflex-shop.de/produkte/terraflex-1k-easy",
    key: "1",
    value: 1.64,
    jointwidth: 4
  },
  {
    title: "TerraFLEX 1k Easy fein",
    image: "/images/Terraflex-easy-fein-VR.jpg",
    description1: "> Fugen ab 2 mm",
    description2: "> PKW befahrbar",
    description3: "> für Keramik",
    link: "https://multiflex-shop.de/produkte/terraflex-1k-easy-fein/",
    key: "2",
    value: 1.55,
    jointwidth: 2
  },
  {
    title: "TerraFLEX 1k Premium fein",
    image: "/images/Terraflex-easy-VR.jpg",
    description1: "> Fugen ab 2 mm",
    description2: "> für Keramik",
    description3: "> hohe Festigkeit",
    link: "https://multiflex-shop.de/produkte/terraflex-1k-premium-fein/",
    key: "3",
    value: 1.55,
    jointwidth: 2
  },
  {
    title: "HadaFLEX 2k 10",
    image: "/images/HadaFLEX-2k-10-VR.jpg",
    description1: "> Fugen ab 4 mm",
    description2: "> PKW befahrbar",
    description3: "> hohe Festigkeit",
    link: "https://multiflex-shop.de/",
    key: "4",
    value: 1.34,
    jointwidth: 4
  },
  {
    title: "HadaFLEX 2k Easy 15",
    image: "/images/Hadaflex_2k-easy-alle-VR.jpg",
    description1: "> Fugen ab 5 mm",
    description2: "> einschlämmbar",
    description3: "> PKW befahrbar",
    link: "https://multiflex-shop.de/produkte/hadaflex-2k-easy-15/",
    key: "5",
    value: 1.45,
    jointwidth: 5
  },
  {
    title: "HadaFLEX 2k Easy 25",
    image: "/images/Hadaflex_2k-easy-alle-VR.jpg",
    description1: "> Fugen ab 6 mm",
    description2: "> einschlämmbar",
    description3: "> Lieferwagen befahrbar",
    link: "https://multiflex-shop.de/produkte/hadaflex-2k-easy-25/",
    key: "6",
    value: 1.45,
    jointwidth: 6
  },
  {
    title: "HadaFLEX 2k Easy 40",
    image: "/images/Hadaflex_2k-easy-alle-VR.jpg",
    description1: "> Fugen ab 8 mm",
    description2: "> einschlämmbar",
    description3: "> LKW belastbar",
    link: "https://multiflex-shop.de/produkte/hadaflex-2k-easy-40/",
    key: "7",
    value: 1.45,
    jointwidth: 8
  }
];*/

const styles = {
  avatar: {
    backgroundColor: blue[100],
    color: blue[600]
  }
};

class SimpleDialog extends React.Component {

  handleListItemClick = product => {
    this.props.onClose(product);
  };

  render() {
    const { products, classes, ...other } = this.props;

    return (
      <Dialog
        aria-labelledby="simple-dialog-title"
        fullWidth
        {...other}
      >
        <div>
          <List>
            {products.length  > 1 ? products.map(product => (
              <ListItem
                button
                onClick={() => this.handleListItemClick(product)}
                key={product.id}
              >
                <ListItemText primary={product.title} />
              </ListItem>
            )) : null}
          </List>
        </div>
      </Dialog>
    );
  }
}

SimpleDialog.propTypes = {
  classes: PropTypes.object.isRequired
};

const SimpleDialogWrapped = withStyles(styles)(SimpleDialog);

class InfoDialog extends React.Component {
  transition = props => {
    return <Slide direction="up" {...props} />;
  };

  render() {
    const { classes, open, onClose, product, apiUrl, ...other } = this.props;

    return (
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={this.transition}
        fullWidth
        {...other}
      >
        <IconButton
          color="inherit"
          onClick={onClose}
          aria-label="Close"
          id={"close-button"}
        >
          <CloseIcon />
        </IconButton>

        <ProductCard product={product} apiUrl={apiUrl} />
      </Dialog>
    );
  }
}

InfoDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func
};

const InfoDialogWrapped = withStyles(styles)(InfoDialog);

class MaterialDialog extends React.Component {
  state = {
    openInfo: false,
    infoButton: false,
    open: false,
    selectedValue: "",
    buttonText: "Materialauswahl",
    currentProduct: {
      /*title: "",
      image: "",
      descriptionOne: "",
      descriptionTwo: "",
      descriptionThree: "",
      link: "",
      id: "",
      value: "",*/
      id: null,
      appType: "",
      title: "",
      imageUrl: "",
      descriptionOne: "",
      descriptionTwo: "",
      descriptionThree: "",
      densityValue: "",
      jointWidth: ""
    }
  };

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClickInfoOpen = () => {
    this.setState({
      openInfo: true
    });
  };

  handleClose = product => {

    !product
      ? this.setState({
          selectedValue: "",
          open: false,
          buttonText: "Materialauswahl"
        })
      :
        this.setState({
          currentProduct: product,
          selectedValue: product.densityValue ? product.densityValue : "",
          open: false,
          buttonText: product.title ? product.title : "Materialauswahl",
          infoButton: !!product.title,
        });

    this.props.onChange(product.densityValue ? product.densityValue : "", product);
  };

  handleClickInfoClose = () => {
    this.setState({ openInfo: false });
  };

  render() {
    const { buttonText, infoButton } = this.state;
    const {products, apiUrl} = this.props;

    return (
      <React.Fragment>
        <MaterialButton
          text={buttonText}
          onClick={this.handleClickOpen}
          styleName={"big"}
          infoButton={infoButton}
          onClickInfo={this.handleClickInfoOpen}
        />

        <SimpleDialogWrapped
          open={this.state.open}
          onClose={this.handleClose}
          products={products}
        />

        <InfoDialogWrapped
          open={this.state.openInfo}
          onClose={this.handleClickInfoClose}
          product={this.state.currentProduct}
          apiUrl={apiUrl}
        />
      </React.Fragment>
    );
  }
}

export default MaterialDialog;
