import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import CloseIcon from "../../../node_modules/@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import WarningIcon from '@material-ui/icons/ErrorOutline';

import PropTypes from "prop-types";

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class ErrorDialog extends Component {
  render() {
    const { message, isOpen = false, handleClose = () => {} } = this.props;

    return (
      <div>
        <Dialog
          open={isOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
          fullWidth
        >
          <IconButton
            color="inherit"
            onClick={handleClose}
            aria-label="Close"
            id={"close-button"}
          >
            <CloseIcon />
          </IconButton>

          <DialogTitle id="alert-dialog-slide-title">
            <WarningIcon color={"error"} style={{fontSize: 45, fontWeight: 500}} />
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {message}
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

ErrorDialog.propTypes = {
  message: PropTypes.string,
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func
};

export default ErrorDialog;
