import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import "./style.css";

/*import purple from '@material-ui/core/colors/purple';
import red from '@material-ui/core/colors/red';

const primary = red[500]; // #F44336
const accent = purple['A200']; // #E040FB
const accent2 = purple.A200; // #E040FB (alternative method)*/

export default props => (
  <div className={"header"}>
    <AppBar position="static" color={"inherit"}>
      <Toolbar>
        <Typography variant="title" color={"inherit"}>
          <img
            src={props.logo}
            alt={props.altText}
            width={props.width}
          />
          {/* {props.title} */}
        </Typography>
        {props.children}
      </Toolbar>
    </AppBar>
  </div>
);
